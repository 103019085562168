
  import { Component, Prop } from 'vue-property-decorator'
  import { Dynamic } from './dynamic'
  import {
    VAutocomplete, VCheckbox, VCombobox, VFileInput, VOtpInput, VOverflowBtn, VRadio,
    VRadioGroup,
    VRangeSlider,
    VSelect,
    VSlider,
    VSwitch,
    VTextarea,
    VTextField,
  } from 'vuetify/lib/components'

  import { InputRule, mapRules } from '@/components/forms/Rules'
  import { InputComponent, InputProps } from '@/entities/public/Resource/interfaces/form'
  import { debounce, getObjectValueByPath } from '@/utils/vuetify/helpers'
  import { Query } from '@/utils/computed/Query'

  const shouldDebounce: Record<InputComponent, number> = {
    input: 1000,
    form: undefined,
    'v-text-field': 1000,
    'hidden-field': 100,
    'hidden-selector': 100,
    'v-select': undefined,
    'g-date-picker': undefined,
    'v-combobox': undefined,
    'v-autocomplete': undefined,
    'linked-form': undefined,
    'button-group': undefined,
    'linked-person': undefined,
    'timeline-text': undefined,
    'timeline-photo': undefined,
    'timeline-phone': undefined,
    'timeline-email': undefined,
  }

@Component({
  components: {
    VTextField,
    VAutocomplete,
    VSelect,
    VSwitch,
    VTextarea,
    VSlider,
    VRangeSlider,
    VRadioGroup,
    VRadio,
    VOverflowBtn,
    VOtpInput,
    VFileInput,
    VCombobox,
    VCheckbox,
    LinkedPerson: () => import('./fields/LinkedPerson.vue'),
    HiddenField: () => import('./fields/HiddenField.vue'),
    HiddenSelector: () => import('./fields/HiddenSelector.vue'),
    ButtonGroup: () => import('./fields/ButtonGroup.vue'),
    LinkedForm: () => import('./fields/LinkedForm.vue'),
    GDatePicker: () => import('../core/input/GDatePicker.vue'),
  },
})
  export default class DynamicField extends Dynamic {
  @Prop({ type: String }) readonly target!: string;
  @Prop({ type: Array }) readonly rules!: Array<InputRule>;

  declare properties: InputProps
  declare item: any

  created () {
    const { properties, target } = this
    const rules = mapRules(this.rules)
    Object.assign(this.bind, { rules }, properties)
    this.updateBind()

    Object.defineProperty(this, 'item', {
      get () {
        return getObjectValueByPath(this.value, target)
      },
    })

    const { queries } = this
    if (queries) Object.entries(queries).forEach(([prop, q]) => q.triggers?.forEach(name => this.registerQueryInTrigger(name, q)))

    if (this.component === 'linked-form' || this.component === 'linked-person') {
      this.triggers['nested:form'] = e => this.$emit('nested:form', e)
    }
  }

  async mounted () {
    await this.updateQueries()
  }

  render (h) {
    const { bind } = this
    if (bind.hidden) return

    const { component, item, target, triggers } = this
    const onInput = value => this.notifyChange(value, target)

    return h(component, {
      attrs: bind,
      style: {
        display: bind.type === 'hidden' ? 'none' : '',
      },
      props: { value: item, ...bind },
      on: {
        input: shouldDebounce[component] ? debounce(onInput, shouldDebounce[component]) : onInput,
        ...triggers,
      },
    })
  }

  registerQueryInTrigger (name, query: Query) {
    const { triggers } = this

    if (!triggers[name]) {
      const queries = [query]

      // TODO: Define different kind of triggers behaviour.
      const trigger = debounce(search => {
        const { item } = this
        if (item) {
          // TODO: Find a better strategy for query_search
          const { properties, item } = this
          const itemText = properties?.itemText || properties?.properties?.itemText
          const text = itemText ? getObjectValueByPath(item, itemText) : item.toString()

          if (text.includes(search)) return
        }

        Promise.all(queries.map(q => {
          query.search = search
          return q.update()
        }))
      }, 500)
      trigger.prototype._registered = queries

      return this.triggers[name] = trigger
    }

    const { _registered } = this.triggers[name].prototype
    if (!_registered.find(query)) _registered.push(query)
  }
  }
