type Rule = (...args) => boolean | string
type Rules = Array<Rule>

const EMAIL_RULES: Rules = [
  (v: string): any => !v || /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(v) ||
    'Correo invalido',
]

const FIELD_REQUIRED: Rules = [
  (v: string): any => !!v || 'Campo requerido',
]

const PPU_RULES: Rules = [
  (v: string): any => !v || v.length > 5 || 'Patente debe tener minimo 6 caracteres',
]

const RUT_RULES: Rules = [
  (v: string): any => !v || v.length > 7 || 'Rut debe tener minimo 8 caracteres',
]

const PHONE_RULES: Rules = [
  (v: string): any => !v || /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
    .test(v) || 'Número de teléfono inválido',
]

const PASSWORD_RULES: Rules = [
  (v: string): any => !!v || 'Contraseña es requerida',
]

const MILEAGE_RULES: Rules = [
  (v: number): any => v === undefined || (/^[0-9]*$/gm.test(v.toString()) || v < 0) || 'Kilometraje Invalido',
]

const AMOUNT_RULES: Rules = [
  (v: number | string): any => {
    if (v === '') return 'Campo requerido'
    if (v === undefined || v === null) return 'Campo requerido'
    if (isNaN(Number(v))) return 'Monto inválido'
    return true
  },
]

const MULTIPLE_OF_FIVE: Rules = [
  (v: number): any => {
    if (v === undefined || v === null || !v) return 'Campo requerido'
    return v % 5 === 0 || 'Debe ser múltiplo de 5'
  },
]

const VALID_URL = [
  v => {
    if (v === undefined || v === null || !v) return true
    if (v.includes('http://') || v.includes('https://')) {
      const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=%]*)*$/
      return regex.test(v) || 'Link inválido'
    }
    const regex = /^(www\.[\da-z.-]+\.[a-z]{2,6}(\/[\w/%-]*)?(\?\S*)?)|(https:\/\/[\da-z.-]+\.[a-z]{2,6}(\/[\w/%-]*)?(\?\S*)?)$/
    return regex.test(v) || 'Link inválido'
  },
]

export type InputRule =
  'isRequired'
  | 'isEmail'
  | 'isPhone'
  | 'isRut'
  | 'isPassword'
  | 'isMileage'
  | 'isAmount'
  | 'isPPU'
  | 'isMultipleOfFive'
  | 'isUrl'

export const RULES: Record<InputRule, Rules> = {
  isRequired: FIELD_REQUIRED,
  isEmail: EMAIL_RULES,
  isPhone: PHONE_RULES,
  isRut: RUT_RULES,
  isPassword: PASSWORD_RULES,
  isMileage: MILEAGE_RULES,
  isAmount: AMOUNT_RULES,
  isPPU: PPU_RULES,
  isMultipleOfFive: MULTIPLE_OF_FIVE,
  isUrl: VALID_URL,
}

export function mapRules (rules: Array<InputRule>) {
  if (!rules) return []

  return rules.map(name => {
    const rule = RULES[name]
    if (!rule) throw new Error(`The "${name}" rule is not registered`)

    return rule
  }).flat(1)
}
